import { PolicyDetails, PolicyHeaderObject } from '@wix/cookie-consent-policy-client'
import { MemberDetails, memberDetailsFromDTO } from 'feature-site-members'
import { PlatformEnvData, PlatformUtils } from '@wix/thunderbolt-symbols'
import {
	SiteMembersWixCodeSdkFactoryData,
	SiteMembersWixCodeSdkHandlers,
	SiteMembersWixCodeSdkWixCodeApi,
	REGISTRATION_RESULT_STATUS_DISPLAY,
	LoginHandler,
	ConsentPolicyChangedHandler,
} from '../types'
import { namespace } from '../symbols'
import { User } from '../user/user'
import { getApis } from '../user/utils'
import { validateEmailUserParams } from './validations'

export async function SiteMembersSdkFactory(
	{
		baseUrl,
		svSession,
		consentPolicyDetails,
		consentPolicyHeaderObject,
		getMemberDetailsUrl,
		isUserLoggedIn,
	}: SiteMembersWixCodeSdkFactoryData,
	{
		login,
		applySessionToken,
		promptForgotPassword,
		promptLogin,
		register,
		registerToUserLogin,
		logout,
		getMemberDetails,
		handleOauthToken,
		setConsentPolicy,
		resetConsentPolicy,
		registerToConsentPolicyChanges,
	}: SiteMembersWixCodeSdkHandlers,
	{ sessionServiceApi }: PlatformUtils,
	{ window: { isSSR } }: PlatformEnvData
): Promise<{ [namespace]: SiteMembersWixCodeSdkWixCodeApi }> {
	const getMemberDetailsForSSR: () => Promise<MemberDetails | null> = () =>
		isUserLoggedIn
			? self
					.fetch(getMemberDetailsUrl)
					.then((r) => r.json())
					.then((r) => (r.errorCode ? null : memberDetailsFromDTO(r.payload)))
			: Promise.resolve(null)

	const memberDetails = isSSR ? await getMemberDetailsForSSR() : await getMemberDetails()
	const currentUser = new User(
		{ ...memberDetails, uid: memberDetails?.id, svSession },
		memberDetails ? REGISTRATION_RESULT_STATUS_DISPLAY[memberDetails.status] : undefined,
		baseUrl,
		sessionServiceApi.getWixCodeInstance()
	)
	let onLogin: Array<LoginHandler> = []

	const clonePolicyDetails = (policyDetails: PolicyDetails) => ({
		...policyDetails,
		policy: {
			...policyDetails.policy,
		},
	})

	const clonePolicyHeaderObject = (policyHeaderObject: PolicyHeaderObject) => ({
		...policyHeaderObject,
	})

	const consentPolicyChangedHandlers: Array<ConsentPolicyChangedHandler> = []
	const { sendUserEmailApi } = getApis(baseUrl)

	const api: SiteMembersWixCodeSdkWixCodeApi = {
		currentUser,
		async login(email, password) {
			await login(email, password)
		},
		applySessionToken,
		async emailUser(emailId, toUser, options) {
			const { processedOptions } = validateEmailUserParams(emailId, toUser, options)
			const params = { emailId, contactId: toUser, options: processedOptions }
			await fetch(sendUserEmailApi, {
				method: 'POST',
				headers: { authorization: sessionServiceApi.getWixCodeInstance() || '' },
				body: JSON.stringify(params),
			})
		},
		promptForgotPassword,
		async promptLogin(options) {
			await promptLogin(options)
			// We can count on currentUser being updated because login is guaranteed not to
			// resolve before all onLogin callbacks (including the one that updates currentMember)
			// have resolved.
			return api.currentUser
		},
		async register(email, password, options = {}) {
			try {
				const data = await register(email, password, options)
				return {
					status: data.status,
					...(data.approvalToken ? { approvalToken: data.approvalToken } : {}),
					user: new User(
						{
							uid: data.user?.id,
							svSession,
							...data.user,
						},
						REGISTRATION_RESULT_STATUS_DISPLAY[data.status],
						baseUrl,
						sessionServiceApi.getWixCodeInstance()
					),
				}
			} catch (error) {
				if (error.message) {
					console.error(error.message)
					return Promise.reject(error.message)
				}
			}
		},
		onLogin(handler: LoginHandler) {
			onLogin = [...onLogin, handler]
		},
		logout,
		async handleOauthToken(token: string, provider: string, mode: string, joinCommunityStatus: string) {
			await handleOauthToken(token, provider, mode, joinCommunityStatus)
		},
		getCurrentConsentPolicy: () => {
			return clonePolicyDetails(consentPolicyDetails)
		},
		_getConsentPolicyHeader: () => {
			return clonePolicyHeaderObject(consentPolicyHeaderObject)
		},
		setConsentPolicy,
		resetConsentPolicy,
		onConsentPolicyChanged: (handler: ConsentPolicyChangedHandler) => {
			consentPolicyChangedHandlers.push(handler)
		},
	}

	if (process.env.browser) {
		registerToConsentPolicyChanges((policyDetails: PolicyDetails, policyHeaderObject: PolicyHeaderObject) => {
			consentPolicyDetails = policyDetails
			consentPolicyHeaderObject = policyHeaderObject
			consentPolicyChangedHandlers.forEach((handler) => handler(clonePolicyDetails(policyDetails)))
		})

		registerToUserLogin(async () => {
			const newMemberDetails = await getMemberDetails()
			api.currentUser = new User(
				{ ...newMemberDetails, uid: newMemberDetails?.id, svSession },
				newMemberDetails ? REGISTRATION_RESULT_STATUS_DISPLAY[newMemberDetails.status] : undefined,
				baseUrl,
				sessionServiceApi.getWixCodeInstance()
			)
			onLogin.forEach((handler) => {
				try {
					handler(api.currentUser)
				} catch (e) {
					console.error(e)
				}
			})
		})
	}

	return {
		[namespace]: api,
	}
}
